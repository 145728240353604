import React, { useEffect, useState } from "react";
import { Button, FormGroup, Input, Label, Modal, ModalHeader, ModalBody } from "reactstrap";
import { parseNumber } from "utility/Helpers";


const BonusModal = ({ isOpen, toggleModal }) => {
    const [rawBaseSalary, setRawBaseSalary] = useState(0);
    const [randomPercentage, setRandomPercentage] = useState(0);
    const [baseSalary, setBaseSalary] = useState(0);
    const [currrentBonus, setCurrrentBonus] = useState(0);
    const [maxPotentialBonus, setMaxPotentialBonus] = useState(0);
    const [maxBonusPercentage, setMaxBonusPercentage] = useState(0);

    useEffect(() => {
        handleCurrentBonusChange()
        handleMaxPotentialBonusChange()
    }, [baseSalary])

    const getRandomPercentage = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const handleBaseSalaryChange = (e) => {
        const inputValue = e.target.value.replace(/,/g, '');

        if (inputValue === '' || isNaN(inputValue)) {
            setRawBaseSalary(0);
            setBaseSalary('');
            return;
        }

        const numericValue = parseFloat(inputValue);
        setRawBaseSalary(numericValue);

        const newRandomPercentage = getRandomPercentage(0, 20);
        setRandomPercentage(newRandomPercentage);

        const formattedValue = parseNumber(numericValue);
        setBaseSalary(formattedValue);
    };

    const handleCurrentBonusChange = () => {
        if (rawBaseSalary === 0) {
            setCurrrentBonus('');
            return;
        }

        const bonus = (rawBaseSalary * randomPercentage / 100) + rawBaseSalary;
        setCurrrentBonus(parseNumber(bonus));
    };

    const handleMaxPotentialBonusChange = () => {
        if (rawBaseSalary === 0) {
            setMaxPotentialBonus('');
            return;
        }

        if (randomPercentage + 5 <= 20) {
            setMaxBonusPercentage(randomPercentage + 5)
        } else if (randomPercentage + 5 > 20) {
            setMaxBonusPercentage(20)
        }

        const bonus = (rawBaseSalary * maxBonusPercentage / 100) + rawBaseSalary;
        setMaxPotentialBonus(parseNumber(bonus));
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={toggleModal}
                className="modal-dialog-centered"
            >
                <ModalHeader toggle={toggleModal}>
                    Bonus calculator
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="salary">Base Salary</Label>
                        <Input
                            type="text"
                            id="baseSalary"
                            value={baseSalary}
                            onChange={(e) => handleBaseSalaryChange(e)}
                            onClick={() => setBaseSalary(0)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="currentBonus">{`Current Bonus (${randomPercentage}%)`}</Label>
                        <Input
                            disabled
                            type="text"
                            id="currentBonus"
                            value={currrentBonus}
                            onChange={handleCurrentBonusChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="maxBonus">{`Maximum Potential Bonus (${maxBonusPercentage}%)`}</Label>
                        <Input
                            disabled
                            type="text"
                            id="maxPotentialBonus"
                            value={maxPotentialBonus}
                            onChange={handleMaxPotentialBonusChange}
                        />
                    </FormGroup>
                    <div className="d-flex justify-content-end">
                        <Button color="primary" onClick={toggleModal}>Close</Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
};

export default BonusModal;
